<template>
  <v-container>
    <v-card class="mx-auto pa-3 mb-3">
      <v-card-title>New Teacher(s)</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <div class="text-h6">Invite teachers</div>
          <v-spacer></v-spacer>
          <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" color="primary" v-on="on">
                Share invitation Link
              </v-btn>
            </template>

            <v-list width="280px">
              <v-list-item>
                <v-list-item-action class="text-center">
                  <ShareNetwork
                    class="text-decoration-none"
                    network="whatsapp"
                    title="Teacher Invitation"
                    :url="invitationUrl"
                  >
                    <v-btn width="250px" rounded color="green" dark>
                      <v-icon left dark> mdi-whatsapp </v-icon>
                      Invite Via whatsapp
                    </v-btn>
                  </ShareNetwork>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-action
                  ><v-btn
                    width="250px"
                    :disabled="copied"
                    @click="copy"
                    rounded
                    color="primary"
                  >
                    <v-icon left dark> mdi-content-copy </v-icon>
                    <span v-if="copied">Copied invitation link</span>
                    <span v-else>Copy invitation link</span>
                  </v-btn></v-list-item-action
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn class="ml-2" text @click="$router.go(-1)"> Cancel </v-btn>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-stepper elevation="0" :value="step" v-if="step < 6">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="1 < step">Input</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="2 < step">Check</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="3 < step">Done</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
        <div class="d-flex align-center justify-space-around">
          <div v-if="step == 1" class="form-content text-center">
            <v-form ref="formName" v-model="valid" lazy-validation>
              <TextareaField
                :seletedTeachers="teachers"
                :callbackSelect="getTeachers"
              ></TextareaField>
            </v-form>
          </div>

          <div v-else-if="step == 2" class="form-content text-center">
            <v-form ref="formName" v-model="valid" lazy-validation>
              <Preview @close="back" :teachers="teachers" />
            </v-form>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-if="step == 1">
        <v-spacer></v-spacer>
        <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
        <v-btn color="primary" @click="next()"> Next </v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="step == 2">
        <v-spacer></v-spacer>
        <v-btn text @click="back()"> Back </v-btn>
        <v-btn color="primary" :loading="api.isLoading" @click="submit()">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
Vue.use(VueSocialSharing);
import TextareaField from "./TextareaField.vue";
import Preview from "./Preview";
export default {
  components: {
    Preview,
    TextareaField,
  },
  computed: mapState({
    //
  }),
  props: [
    "callbackSuccess"
  ],
  data: () => ({
    url: null,
    invitationUrl: null,
    copied: false,
    step: 1,
    teachers: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC

    //BOC:[api]
    invitationApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),

  created() {
    this.invitationApi.method = "GET";
    this.invitationApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/moderator/institution/" +
      this.$route.params.parentId +
      "/teacher/generateInvitation";

    this.invitationApi.callbackReset = () => {
      this.invitationApi.isLoading = true;
      this.invitationApi.isError = false;
    };

    this.invitationApi.callbackError = (e) => {
      this.invitationApi.isLoading = false;
      this.invitationApi.isError = true;
      this.invitationApi.error = e;
    };

    this.invitationApi.callbackSuccess = (resp) => {
      this.invitationApi.isLoading = false;
      this.invitationUrl = resp.link;
    };

    //BOC
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/moderator/institution/" +
      this.$route.params.parentId +
      "/teacher/addMany";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  mounted() {
    this.$api.fetch(this.invitationApi);
  },
  methods: {
    next() {
      this.step++;
    },
    back() {
      this.step--;
    },
    checkMobile() {
      const hasError = this.$_.some(this.teachers, (item) => {
        if (
          !item.mobile ||
          item.mobile.length < 7 ||
          item.mobile.length >= 15 ||
          !Number.isInteger(Number(item.mobile))
        ) {
          return true;
        }
        return false;
      });
      return hasError;
    },
    getTeachers(val) {
      this.teachers = val;
      for (var i = 0; i < this.teachers.length; i++) {
        if (this.teachers[i].name == "") {
          this.teachers.splice(i, 1);
          i--;
        }
        if (this.teachers[i].mobile) {
          let mobile = this.teachers[i].mobile.replace(/[\s-]+/g, "");
          if (mobile.startsWith("0")) {
            this.$_.set(this.teachers[i], "mobile", "+6" + mobile);
          } else if (mobile.startsWith("6")) {
            this.$_.set(this.teachers[i], "mobile", "+" + mobile);
          } else if (mobile.startsWith("1")) {
            this.$_.set(this.teachers[i], "mobile", "+60" + mobile);
          } else if (mobile.startsWith("+")) {
            this.$_.set(this.teachers[i], "mobile", mobile);
          } else {
            this.$_.set(this.teachers[i], "mobile", "+60" + mobile);
          }
        }
      }
    },
    submit() {
      if (this.checkMobile()) {
        this.api.callbackError("Please check the form.");
      } else {
        this.api.params = this.$_.clone({
          data: this.teachers,
        });
        this.$api.fetch(this.api);
      }
    },
    copy() {
      navigator.clipboard.writeText(this.invitationUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
  },
};
</script>